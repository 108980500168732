<template>
   <div class="container">
    <content-title :nav="nav"></content-title>
    <el-table
      :data="list"
      :fit="true"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center' }"
      @row-click="openDrawer"
    >
      <el-table-column label="标题" prop="title"></el-table-column>
      <!-- <el-table-column label="发件人" prop="real_name"></el-table-column> -->
      <el-table-column label="发布时间" prop="create_time"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <p v-if="row.user_status == 1">未读</p>
          <p v-if="row.user_status == 2">已读</p>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="Total"
    ></paging-fy>

    <!-- 消息 -->
    <el-drawer title="消息" :visible.sync="drawer" direction="rtl">
      <div class="msg-main">
        <div class="row">{{ changeMsg.title }}</div>
          <div class="row">{{ changeMsg.content || '' }}</div>
        <div
          class="row"
          v-if="
            changeMsg.large_type == 'coach_mechanism' &&
            changeMsg.dispose_status == 0
          "
        >
          <el-button
            type="primary"
            size="small"
            round
            @click="confirm(changeMsg, 1)"
            >同意</el-button
          >
          <el-button
            type="primary"
            size="small"
            round
            @click="confirm(changeMsg, 2)"
            >拒绝</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      nav: { firstNav: "消息管理", secondNav: "消息列表" },
      drawer: false,
      activeName: "",
      currentPage: 1,
      list: [],
      changeMsg: {},
    Total:0
    };
  },

  mounted() {
    this.getMessageList();
  },
  //user_status 读状态 1未读 2 已读
  //dispose_status 处理状态(0未处理,1同意,2拒绝)
  methods: {
    openDrawer(row) {
      this.changeMsg = row;
      this.drawer = true;
    },
    confirm(item, s) {
      this.$axios({
        url: "user/pushHistory/update",
        data: {
          id: item.id,
          dispose_status: s,
        },
        method: "post",
      }).then((res) => {
        this.$message("成功");
      });
    },
    changeCurrentPage(v){
        this.currentPage = v
        this.getMessageList()
    },
    getMessageList() {
      this.$axios({
        url: "user/pushHistory/queryManagerListPage",
        params: {
            target_id:JSON.parse(localStorage.getItem("managerInfo")).id,
          currenPage: this.currentPage,
          pageSize: 10,
          type: "推送",
        },
      }).then((res) => {
        this.list = res.data.data.rows;
        this.Total = res.data.data.total
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
  .msg-main{
    padding: 0 20px;
    font-size: 14px;
    color: #333;
  }
.row {
  margin-bottom: 10px;
}
</style>